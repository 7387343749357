import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CobrancaDTO, Invoice } from '../types';
import { endpoint } from '../../../environments';

@Injectable({
  providedIn: 'root',
})
export class PagamentoService {
  private url = endpoint(`/api/solucao-pagamentos`);

  constructor(private http: HttpClient) {}

  getInvoice(uuid: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${this.url}/fatura/${uuid}`);
  }

  payInvoice(
    uuidFatura: string,
    uuidFormaPagamento: string
  ): Observable<CobrancaDTO> {
    return this.http.post<CobrancaDTO>(`${this.url}/fatura/${uuidFatura}/pagar`, {
      uuidFatura,
      uuidFormaPagamento,
    });
  }
}
