import { Locale } from '../../core/types';

export class Utils {
  static normalize(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  static formatDateString(str: string, locale: Locale = 'ptBR'): string {
    if (locale === 'en') {
      return str.slice(5, 7) + '/' + str.slice(8, 10) + '/' + str.slice(0, 4);
    }

    return str.slice(0, 10).split('-').reverse().join('/');
  }

  static formateTimeString(str: string): string {
    return str.slice(11, 19);
  }
}
