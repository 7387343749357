export const copyDetails = {
  ptBR: {
    id: 'Identificador da fatura não fornecido',
    fetching: 'Buscando fatura...',
    notFound: 'Fatura não encontrada',
    details: 'Detalhes da fatura',
    status: 'Status',
    resident: 'Morador(a)',
    enterprise: 'Empreendimento',
    unit: 'Unidade',
    total: 'Total',
    period: 'Período de competência',
    dueDate: 'Data de vencimento',
    value: 'Valor',
    rent: 'Aluguel',
    condominium: 'Condomínio',
    tax: 'IPTU',
    expense: 'Reembolso de Despesas',
    fine: 'Multa',
    bankslipFine: 'Multa de Boleto',
    discount: 'Desconto',
    commercialDiscount: 'Desconto Comercial',
    subscription: 'Recorrência',
    creditBoleto: 'Crédito/Boleto',
    cartao: 'Cartão',
    boleto: 'Boleto',
    pix: 'PIX',
    receipt: 'Recibo',
    loadingPayment: 'Carregando informações de pagamento',
    pay: 'Pagar',
    unexpectedError: 'Ocorreu um erro inesperado',
  },
  en: {
    id: 'Invoice ID not provided',
    fetching: 'Fetching invoice...',
    notFound: 'Invoice not found',
    details: 'Details of invoice',
    status: 'Status',
    resident: 'Resident',
    enterprise: 'Enterprise',
    unit: 'Unit',
    total: 'Total',
    period: 'Period',
    dueDate: 'Due date',
    value: 'Value',
    rent: 'Rent',
    condominium: 'Condominium',
    tax: 'Tax',
    expense: 'Expense',
    fine: 'Fine',
    bankslipFine: 'Boleto Fine',
    discount: 'Discount',
    commercialDiscount: 'Commercial Discount',
    subscription: 'Subscription',
    pix: 'PIX',
    cartao: 'Card',
    boleto: 'Boleto',
    receipt: 'Receipt',
    loadingPayment: 'Loading payment',
    pay: 'Pay',
    unexpectedError: 'An unexpected error occurred',
  },
};

export const invoiceStatus = {
  ptBR: {
    EMITIDA: 'Emitida',
    PAGA: 'Paga',
    VENCIDA: 'Vencida',
    REEMBOLSO_PENDENTE: 'Estorno Solicitado',
    REEMBOLSADA: 'Estornada',
    CANCELADA: 'Cancelada',
    CONCILIADA: 'Conciliada',
    PAGAMENTO_PENDENTE: 'Pendente',
    RASCUNHO: 'Rascunho',
  },
  en: {
    EMITIDA: 'Issued',
    VENCIDA: 'Expired',
    REEMBOLSO_PENDENTE: 'Refund Requested',
    REEMBOLSADA: 'Refunded',
    CONCILIADA: 'Reconciled',
    PAGAMENTO_PENDENTE: 'Pending',
    RASCUNHO: 'Draft',
    PAGA: 'Paid',
    CANCELADA: 'Canceled',
  },
};

export const dicionarioItensContrato = {
  en: {
    CONDOMINIO: 'Condominium',
    IPTU: 'IPTU',
    VALOR: 'Valor',
    REEMBOLSO_DESPESA: 'Refund',
    ALUGUEL: 'Rent',
    DESCONTO_COMERCIAL: 'Commercial discount',
    DESCONTO_FINANCEIRO: 'Financial discount',
    DESCONTO: 'Discount',
    DESPESAS_IMOBILIARIAS: 'Expenses',
    MULTA: 'Fee'
  },
  ptBR: {
    CONDOMINIO: 'Condomínio',
    IPTU: 'IPTU',
    VALOR: 'Valor',
    REEMBOLSO_DESPESA: 'Reembolso despesa',
    ALUGUEL: 'Aluguel',
    DESCONTO_COMERCIAL: 'Desconto comercial',
    DESCONTO_FINANCEIRO: 'Desconto financeiro',
    DESCONTO: 'Desconto',
    DESPESAS_IMOBILIARIAS: 'Despesas imobiliárias',
    MULTA: 'Multa'
  },
};

export const dicionarioTipoFatura = {
  ALUGUEL: {
    label: 'Aluguel',
    class: 'badge badge-info',
  },
  RESERVA: {
    label: 'Reserva',
    class: 'badge badge-info',
  },
  MULTA: {
    label: 'Multa',
    class: 'badge badge-info',
  },
  SPOT: {
    label: 'Spot',
    class: 'badge badge-info',
  },
  AVARIA: {
    label: 'Avaria',
    class: 'badge badge-info',
  },
};
