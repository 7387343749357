import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Stripe,
  StripeElements,
  StripePaymentElement,
  loadStripe,
} from '@stripe/stripe-js';
import { baseUrl, stripeApiKey } from '../../environments';
import { Utils } from '../@shared/utils/utils';
import { copyDetails } from '../core/dictionary';
import {
  CobrancaBoletoDTO,
  CobrancaDTO,
  CobrancaPixDTO,
  FormaPagamentoNormalizado,
  Locale,
} from '../core/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @Input() locale: Locale = 'ptBR';
  @Input() cobranca: CobrancaDTO = {} as CobrancaDTO;
  stripe: Stripe | null = null;
  stripeElements: StripeElements | null = null;
  stripePaymentElement: StripePaymentElement | null = null;
  stripeErrorMessage: string | null = null;

  loading: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.cobranca.formaPagamento.nome === 'Cartão') {
      if (this.stripe) {
        this.mountPaymentElement();
      } else {
        loadStripe(stripeApiKey).then((stripe: any) => {
          if (!stripe) {
            // TODO: mostrar um feedback para avisar o usuário
            throw new Error('Não foi possível inicializar o Stripe');
          }
          this.stripe = stripe;
          this.mountPaymentElement();
        });
      }
    }else if (this.cobranca.formaPagamento.nome === 'Boleto'){
        window.open(this.getBoletoDetails().pdf, "_blank");
      }
  }

  private mountPaymentElement(): void {
    this.stripeElements = (this.stripe as Stripe).elements({
      clientSecret: this.cobranca?.stripeClientSecret,
    });
    this.stripePaymentElement = this.stripeElements.create('payment');
    this.stripePaymentElement.mount('#payment-element');
  }

  getButtonLabel(): string {
    return copyDetails[this.locale]['pay'];
  }

  getPixDetails(): CobrancaPixDTO {
    return this.cobranca.formaPagamento as CobrancaPixDTO;
  }

  getBoletoDetails(): CobrancaBoletoDTO {
    return this.cobranca.formaPagamento as CobrancaBoletoDTO;
  }

  getVencimentoPix(): string {
    const pix = this.cobranca.formaPagamento as CobrancaPixDTO;
    return `Pagamento válido até ${Utils.formatDateString(
      pix.dataVencimento
    )} às ${Utils.formateTimeString(pix.dataVencimento)}`;
  }

  onClipboardCopy(successful: boolean): void {
    if (successful) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Código copiado com sucesso!",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  normalizeNomeFormaPagamento(nome: string) {
    return Utils.normalize(nome) as FormaPagamentoNormalizado;
  }

  async onClickStripePay(e: any) {
    e.preventDefault();

    this.stripeErrorMessage = null;

    if (!this.stripe || !this.stripeElements || !this.stripePaymentElement) {
      this.stripeErrorMessage = 'Stripe não está configurado';
      throw new Error(this.stripeErrorMessage);
    }

    this.loading = true;

    const { error } = await this.stripe.confirmPayment({
      elements: this.stripeElements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: baseUrl + this.router.url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      this.stripeErrorMessage =
        error.message ?? copyDetails[this.locale]['unexpectedError'];
    } else {
      this.stripeErrorMessage = copyDetails[this.locale]['unexpectedError'];
    }

    this.loading = false;
  }
}
