import { ActivatedRoute } from '@angular/router';
import {
  CobrancaDTO,
  FormaPagamento,
  FormaPagamentoNormalizado,
  Invoice,
  Locale,
} from '../core/types';
import {
  copyDetails,
  dicionarioItensContrato,
  invoiceStatus,
} from './../core/dictionary';
import { Component, OnInit } from '@angular/core';
import { PagamentoService } from '../core/services/pagamento.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Utils } from '../@shared/utils/utils';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  public invoice = {} as Invoice;
  public cobranca: CobrancaDTO | null = null;
  public locale: Locale = 'ptBR';
  public copy = copyDetails;
  public invoiceStatus = invoiceStatus;
  public dicionarioItensContrato = dicionarioItensContrato;
  public loading = true;
  public valid = true;
  public loadingPayment = false;
  public paymentError: string = '';

  stripeRedirect: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private pagamentoService: PagamentoService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['uuid']) {
        this.pagamentoService
          .getInvoice(params['uuid'])
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.invoice = res;
              this.loading = false;
            },
            (err) => {
              this.valid = false;
            }
          );
      }
    });

    this.route.queryParams.subscribe((queryParams) => {
      this.stripeRedirect = queryParams['redirect_status'];
      console.log(this.stripeRedirect);
    });
  }

  normalizeNomeFormaPagamento(nome: string) {
    return Utils.normalize(nome) as FormaPagamentoNormalizado;
  }

  pay(form: FormaPagamento) {
    this.loadingPayment = true;
    this.paymentError = '';
    this.cobranca = null;
    this.pagamentoService.payInvoice(this.invoice.uuid, form.uuid).subscribe({
      next: (cobranca) => {
        this.loadingPayment = false;
        this.paymentError = '';
        this.cobranca = cobranca;
      },
      error: (err) => {
        this.loadingPayment = false;
        this.paymentError = err.error.message;
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Houve um problema com o pagamento!\nEntre em contato com o suporte da JFL para mais informações.',
          confirmButtonColor: '#92C5CE',
        });
      },
    });
  }
}
