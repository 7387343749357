<button
  (click)="clickButton()"
  type="button"
  [disabled]="disabled"
  class="text-black font-bold first-line:text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:ring-gray-100 rounded-lg text-sm px-5 py-4 text-center inline-flex items-center dark:bg-white dark:text-gray-900 dark:hover:bg-gray-200 mr-2 mb-2"
>
  <svg
    *ngIf="formaPagamento === 'cartao'"
    class="h-6 w-6 text-black"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
    />
  </svg>
  <svg
    *ngIf="formaPagamento === 'pix'"
    class="h-6 w-6 text-black"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"
    />
  </svg>
  <svg
    version="1.1"
    *ngIf="formaPagamento === 'boleto'"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-6 w-6 text-black"
    viewBox="0 0 533.333 533.334"
    style="enable-background: new 0 0 533.333 533.334"
    xml:space="preserve"
  >
    <g>
      <path
        d="M0,66.667h66.667V400H0V66.667z M100,66.667h33.333V400H100V66.667z M166.667,66.667H200V400h-33.333V66.667z
		 M266.667,66.667H300V400h-33.333V66.667z M400,66.667h33.334V400H400V66.667z M500,66.667h33.334V400H500V66.667z M333.333,66.667
		H350V400h-16.666V66.667z M233.333,66.667H250V400h-16.667V66.667z M450,66.667h16.667V400H450V66.667z M0,433.333h33.333v33.334H0
		V433.333z M100,433.333h33.333v33.334H100V433.333z M166.667,433.333H200v33.334h-33.333V433.333z M333.333,433.333h33.333v33.334
		h-33.333V433.333z M500,433.333h33.334v33.334H500V433.333z M400,433.333h66.667v33.334H400V433.333z M233.333,433.333H300v33.334
		h-66.667V433.333z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <span class="ml-1">{{ title }}</span>
</button>
