<div *ngIf="valid; else invalidId">
  <div
    class="h-screen w-full place-self-center flex items-center flex-col justify-center lg:text-left md:px-12 overflow-y-auto overflow-x-hidden"
    *ngIf="!loading; else loadingScreen"
  >
    <div class="flex mb-6 items-center text-center">
      <img class="box-border w-28 mx-auto" src="../../assets/images/logo.png" />
    </div>
    <div class="header">
      <span
        class="text-2xl font-semibold text-gray-800 md:text-2xl mr-4 ml-2"
        >{{ copy[locale]["details"] }}</span
      >
      <p class="mt-1 text-black text-1xl font-bold mr-2 ml-2">
        {{ copy[locale]["status"] }}:
        <span class="invoice-badge status">
          {{ invoiceStatus[locale][invoice.status] }}</span
        >
      </p>
      <div class="flex flex-col justify-between my-4 ml-2 mr-2 sm:flex-row">
        <div>
          <p class="text-lg font-semibold text-neutral-700">
            {{ copy[locale]["resident"] }}: {{ invoice.contrato.locatario }}
          </p>
          <p class="mt-0.5 text-black font-bold text-sm">
            {{ copy[locale]["enterprise"] }}:
            <span class="mt-0.5 text-neutral-400 text-sm">{{
              invoice.contrato.empreendimento
            }}</span>
          </p>
          <p class="mt-0.5 text-black font-bold text-sm">
            {{ copy[locale]["unit"] }}:
            <span class="mt-0.5 text-neutral-400 text-sm">{{
              invoice.contrato.unidade
            }}</span>
          </p>
          <li class="flex items-center mr-3 mt-3 md:mt-0"></li>
        </div>
        <div class="sm:text-right text-left">
          <p class="text-lg font-semibold text-neutral-700">
            {{ copy[locale]["total"] }} :
            {{ invoice.valorTotal | currency : "BRL" }}
          </p>

          <p class="mt-0.5 text-black font-bold text-sm">
            {{ copy[locale]["period"] }}:
            <span class="mt-0.5 text-neutral-400 text-sm">
              {{ invoice.dataInicioCompetencia | date : "dd/MM/yyyy" }} -
              {{ invoice.dataFimCompetencia | date : "dd/MM/yyyy" }}
            </span>
          </p>
          <p class="mt-0.5 text-black font-bold text-sm">
            {{ copy[locale]["dueDate"] }}:
            <span class="ml- 0.5 mt-0.5 text-neutral-400 text-sm">{{
              invoice.dataVencimento | date : "dd/MM/yyyy"
            }}</span>
          </p>
        </div>
      </div>
      <hr class="my-3 border-gray-200 sm:mx-auto dark:border-gray-700" />

      <dl class="ml-2 pb-2 space-y-1 mt-0.5 text-neutral-400 text-sm grid-container">
        <div key="{index}" *ngFor="let info of invoice.itensFatura" class="mt-2 grid-row">
          <div *ngIf="info.valor" class="flex items-center grid-row gap-2">
            <dt class="inline text-black font-bold">
              {{ dicionarioItensContrato[locale][info.nome] }}:
            </dt>
            <span class="separator-dotted"> </span>
            <span [class]="info.nome.includes('DESCONTO')?  'text-danger' : 'text-success'">
              {{ info.valor | currency : "BRL" }}
            </span>
          </div>
        </div>
      </dl>
      <div class="mt-2 text-black text-1xl font-bold flex gap-1 justify-end">
        <span>
          {{ copy[locale]["total"] }}:
        </span>
        <span> {{ invoice.valorTotal | currency : "BRL" }}</span>
      </div>
      <div class="mx-auto">
        <div class="row ml-2" *ngIf="paymentError">
          <span class="text-red-400">{{ paymentError }}</span>
        </div>
        <div class="my-2 text-center" *ngIf="stripeRedirect">
          <p
            [innerText]="
              stripeRedirect === 'succeeded' && invoice.status !== 'PAGA'
                ? 'Pagamento efetuado, aguarde a confirmação'
                : ''
            "
          ></p>
        </div>
        <div
          class="flex flex-wrap justify-center mt-3 mb-6 w-full"
          *ngIf="
            !stripeRedirect &&
            (invoice.status === 'EMITIDA' ||
              invoice.status === 'PAGAMENTO_PENDENTE' ||
            invoice.status === 'VENCIDA')
          "
        >
          <div *ngFor="let formaPagamento of invoice.formasPagamento">
            <app-pay-button
              (onClick)="pay(formaPagamento)"
              [formaPagamento]="
                normalizeNomeFormaPagamento(formaPagamento.nome)
              "
              [locale]="locale"
              [disabled]="loadingPayment"
            ></app-pay-button>
          </div>
        </div>
        <div class="spinner" *ngIf="loadingPayment">
          <span class="text-neutral-400 text-sm">{{
            copy[locale]["loadingPayment"]
          }}</span>
          <div class="loader"></div>
        </div>
        <div *ngIf="!stripeRedirect && !loadingPayment && cobranca">
          <app-payment [locale]="locale" [cobranca]="cobranca"></app-payment>
        </div>
      </div>
    </div>
    <div
      class="flex justify-center lg:justify-start mt-3 mb-6"
      *ngIf="invoice.recibo != null && invoice.status === 'PAGA'"
    >
      <button class="botao-recibo" ><a [href]="invoice.recibo" target="_blank">Recibo</a></button>

    </div>
  </div>
  <ng-template #loadingScreen>
    <div
      class="h-screen w-full place-self-center flex items-center flex-col justify-center lg:text-left md:px-12"
    >
      Carregando...
    </div>
  </ng-template>
</div>

<ng-template #invalidId>
  <div
    class="h-screen w-full place-self-center flex items-center flex-col justify-center lg:text-left md:px-12"
  >
    Fatura inválida
  </div>
</ng-template>
