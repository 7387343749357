import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormaPagamentoNormalizado, Locale } from '../../core/types';
import { copyDetails } from '../../core/dictionary';

@Component({
  selector: 'app-pay-button',
  templateUrl: './pay-button.component.html',
  styleUrls: ['./pay-button.component.scss'],
})
export class PayButtonComponent implements OnInit {
  @Input() formaPagamento: FormaPagamentoNormalizado | '' = '';
  @Input() locale: Locale = 'ptBR';
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  title: string = '';

  ngOnInit(): void {
    if (this.formaPagamento !== '') {
      this.title = copyDetails[this.locale][this.formaPagamento] || '';
    }
  }

  clickButton() {
    this.onClick.emit();
  }
}
